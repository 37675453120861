import nookies from "nookies"
import axios from 'axios'
import { REFRESH_TOKEN } from "lib/graphql"
import { initializeApollo } from "lib/apollo"

/**
 *
 * @param {*} ctx
 * @returns
 */
export const TokenValidation = async (ctx) => {

   let accessToken = nookies.get(ctx)[`${process.env.INITIAL_TOKEN}access_token`]
   let refreshToken = nookies.get(ctx)[`${process.env.INITIAL_TOKEN}refresh_token`]
   let guestToken = nookies.get(ctx)[`${process.env.INITIAL_TOKEN}guest_token`]

   if (accessToken) {
      return { token: accessToken, user_status: true }
   } else {
      if (!guestToken) {
         //!GET NEW GUEST TOKEN
         let { data } = await axios({
            url: process.env.GET_TOKEN_URI,
            method: "POST",
            data: {
               grant_type: process.env.TOKEN_TYPE,
               client_id: process.env.PASSPORT_CLIENT_ID,
               client_secret: process.env.PASSPORT_CLIENT_SECRET,
            },
         })
         if (data) {
            nookies.set(ctx, `${process.env.INITIAL_TOKEN}guest_token`, data.access_token, {
               maxAge: 11 * 24 * 60 * 60,
               path: "/"
            })
            return { token: data?.access_token, user_status: false }
            // return { token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiNWZkZWMwZDFiYjg1MmNiNzkwOGY0YTc5Y2I1NGE4OTFmZDFiNDBkOWNkODg1ZjcwZWIwZDE2MjU1ZGNjNTc2MDNjNzU0YzFmZDdkM2I0NmIiLCJpYXQiOjE2NTM5MDYwMjcuNDEzNDMyLCJuYmYiOjE2NTM5MDYwMjcuNDEzNDM3LCJleHAiOjE2NTUyMDIwMjcuNDA2MDI5LCJzdWIiOiIiLCJzY29wZXMiOltdfQ.THt9SsekZp_24j3xumHVUMJYLLfBaojAEeuKBw4uDSLjpb1OYzKkaS6YfOowV0Kbm2htACWF4lBR2X1qtjEIF9f-1MZY2ntRHCF8Mk25rM6IJ4QzP3fJmNJo42ehf7VUu1pKrCEbv5gtLQrkBylNsJf-AeZbjQeeRV4SoQSomgx0JZhRuQjTg8DJreJy5Quqy8F2Tz1aEjyPYwsotlUzrWYW4rMnOCrxOsnxzjX7sd_GE8P6IYRt-L4JXB51tB_TSwXLGfGhQJKO3lQjMmjTCnKibVfDeejuqG8QbAd2oZDph5ixqGkiy9Miwz3sxFzv1wSdDhXXbS5pTN5vLpqBHcAFesLhW3mwVbyHBoc_iM6UXX2xpwG1Xych8z6t3DMSUCNgf1QnYXL8FWn3NaXnMMRF1fLv_U2Di1E0J0vBTfjNz2_I7HqN-ZaQmjgvAOxTBN_M88c7Vz2AKCpur5RCvFN88UxSOBJlBo7QaZKG5HW_MT0ewxRaENav3fNPRem3uDnQigYz761x8AV4IKu36VvDTBnRTNFb7eClbP6WSrjjCnC74lVHwARLeC4vye4QtEAoCWhfCPjkPYVZS8SA5qlYqAOrrJUepikWObGBUGMscOn0IeDe-zkivGbhkMk0HpgitzL5U8l3olcDj0b8YrI6v6t5Z_534ehY9gSRXZk" }
         }
      } else {
         return { token: guestToken, user_status: false }
      }
   }
}