import { SET_USER_DATA, SET_ADDRESS, SET_CARTS } from "../types"


export const setUserData = (payload) => (dispatch) => {
    if (payload?.addresses?.length > 0) dispatch({ type: SET_ADDRESS, payload: payload.addresses });
    if (payload.carts?.items.length > 0) dispatch({ type: SET_CARTS, payload: payload.carts });
    dispatch({
        type: SET_USER_DATA,
        payload: payload.me,
    })
}

export const setCarts = payload => dispatch => {
    if (payload.carts?.items.length > 0) dispatch({ type: SET_CARTS, payload: payload.carts });
}