// import { SET_USER_DATA, SET_CARTS, SET_ADDRESS, SET_WISHLIST, SET_CATEGORIES } from 'lib/redux/types'
// import { GET_MY_DATA, Q_MY_CART, Q_CATEGORIES } from '../graphql'
// import { TokenValidation } from "lib/function/token-validation";
// import { initializeStore, useStore } from "lib/redux";
// import { initializeApollo } from "lib/apollo";

// /**
//  *
//  * @param {*} token
//  * @param {*} user_status
//  * @param {*} client
//  * @returns
//  */
// export const getInitialData = async (appContext, fn) => {

//    //!Validated token status
//    let { token, user_status } = await TokenValidation(appContext.ctx);

//    const reduxStore = initializeStore();
//    const { dispatch } = reduxStore;

//    const client = initializeApollo(token);

//    //!Custom Functions => Free to Edit
//    // let { locale, locales } = appContext.ctx;
//    // dispatch({ type: "SET_LOCALE", payload: locale });
//    // dispatch({ type: "SET_LOCALES", payload: locales });

//    //!Get Initial Data
//    if (typeof window === "undefined") {
//       //!Get Initial data function below here,
//       if (user_status) {
//          //If User Already Login
//          try {
//             let { data, errors } = await client.query({ query: GET_MY_DATA, variables: { lang: "en" }, errorPolicy: "all" });
//             if (data) {
//                let { me, addresses, carts, wishlist, categories } = data;
//                dispatch({ type: SET_CARTS, payload: carts });
//                dispatch({ type: SET_ADDRESS, payload: addresses });
//                dispatch({ type: SET_USER_DATA, payload: me });
//                dispatch({ type: SET_WISHLIST, payload: wishlist.length });
//                dispatch({ type: SET_CATEGORIES, payload: categories });
//             }
//          } catch (error) {
//             return { notFound: true }
//          }
//       } else {
//          //If Guest (NOT LOGIN)
//          try {
//             let { data } = await client.query({ query: Q_MY_CART });
//             let categories = await client.query({ query: Q_CATEGORIES, variables: { lang: "en" } });
//             if (categories?.data) {
//                dispatch({ type: SET_CATEGORIES, payload: categories.data?.categories })
//             }
//             if (data && data?.carts) {
//                dispatch({ type: SET_CARTS, payload: data.carts });
//             }
//          } catch (error) {
//             return { notFound: true }
//          }
//       }
//    }
//    if (fn) {
//       return fn(appContext, reduxStore, token)
//    }
//    return;

// }

import { SET_USER_DATA } from "lib/redux/types"
import { GET_MY_DATA, Q_CARTS_GUEST, Q_CATALOGS, Q_COUNTRIES } from "../graphql"
import nookies from "nookies"

/**
 *
 * @param {*} token
 * @param {*} user_status
 * @param {*} client
 * @returns
 */
export const getInitialData = async (user_status, client, ctx) => {
  const currency = nookies.get(ctx)?.currency
  let backData = {
    user_data: null,
    initialData: null,
    addresses: null,
    countries: null,
    catalogs: null,
    catalogFeatured: null,
  }

  if (user_status) {
    let { data } = await client.query({
      query: GET_MY_DATA,
      errorPolicy: "all",
      variables: { currency },
    })
    const countries = await client.query({ query: Q_COUNTRIES })
    const catalogs = await client.query({
      query: Q_CATALOGS,
      variables: { currency },
    })
    const catalogsFeatured = await client.query({
      query: Q_CATALOGS,
      variables: { is_featured: 1, currency },
    })
    backData.user_data = { ...data }
    backData.countries = [...countries?.data?.countries]
    backData.catalogs = [...catalogs?.data?.catalogs?.products]
    backData.catalogFeatured = [...catalogsFeatured?.data?.catalogs?.products]
  }
  // let { data, errors } = await client.query({ query: Q_INITIAL_HOME_DATA })
  return { ...backData }
}

export const getGuestData = async (client, ctx) => {
  const currency = nookies.get(ctx)?.currency

  let backData = {
    cartGuest: null,
    catalogFeatured: null,
  }

  let { data } = await client.query({
    query: Q_CARTS_GUEST,
    fetchPolicy: "all",
    errorPolicy: "all",
    variables: { currency }
  })
  const catalogsFeatured = await client.query({
    query: Q_CATALOGS,
    variables: { is_featured: 1, currency },
  })

  backData.cartGuest = { ...data }
  backData.catalogFeatured = [...catalogsFeatured?.data?.catalogs?.products]

  // if (data) return { data }
  return { ...backData }
}
