import App from "next/app"
import nookies, { parseCookies } from "nookies"
import nProgress from "nprogress"
import TagManager from "react-gtm-module"
import { useEffect } from "react"
import { Provider } from "react-redux"
import { Router, useRouter } from "next/router"
import { ApolloProvider } from "@apollo/client"
import { Web3ReactProvider } from "@web3-react/core"
import { Web3Provider } from "@ethersproject/providers"
import { LoadingProvider } from "context/loading-context"
import { ErrorStateProvider } from "context/error-msg-context"
import { initializeStore, useStore } from "lib/redux"
import { initializeApollo, useApollo } from "lib/apollo"
import { setUserData, setCarts } from "lib/redux/actions"
import { TokenValidation } from "lib/function/token-validation"
import { getGuestData, getInitialData } from "lib/function/get-initialdata"
import Head from 'next/head'
import Nookies from 'nookies'
import {
  SET_CATALOGS,
  SET_CATALOGS_FEATURED,
  SET_COUNTRIES,
  SET_CURRENCY,
} from "lib/redux/types"
import "assets/scss/main.scss"
import axios from "axios"

nProgress.configure({ showSpinner: false })
Router.events.on("routeChangeStart", (url) => nProgress.start())
Router.events.on("routeChangeComplete", () => nProgress.done())
Router.events.on("routeChangeError", () => nProgress.done())

const getLibrary = (provider) => {
  return new Web3Provider(provider)
}

function MyApp({ Component, pageProps }) {
  const cookies = parseCookies()
  const token = cookies[`${process.env.ACCESS_TOKEN}`] ?
    cookies[`${process.env.ACCESS_TOKEN}`] :
    cookies[`${process.env.GUEST_TOKEN}`]
  const routerNext = useRouter()
  const store = useStore(pageProps?.initialReduxState)
  const client = useApollo(pageProps?.initialApolloState, token)

  useEffect(() => {
    if (TagManager.initialize) {
      TagManager.initialize({
        gtmId: process.env.GOOGLE_TAG_MANAGER,
      })
    }
  }, [])

  useEffect(() => {
    const os = window?.navigator?.platform
    const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"]

    if (windowsPlatforms.indexOf(os) !== -1) {
      const btnval = document.querySelectorAll(".btn-oval")
      const btnvalDark = document.querySelectorAll(".btn-oval-dark")
      btnval.forEach((btn) => {
        btn.classList.add("windows")
      })
      btnvalDark.forEach((btn) => {
        btn.classList.add("windows")
      })
    }
  }, [routerNext.pathname])

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <script
          type="text/javascript"
          src="https://js.xendit.co/v1/xendit.min.js"
        ></script>
        <Head>
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `window.$crisp=[];window.CRISP_WEBSITE_ID="dd23181a-c2fe-4ba6-bc10-c7c6c2a09155";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`
            }}
          >
          </script>
        </Head>
        <LoadingProvider>
          <ErrorStateProvider>
            <ApolloProvider client={client}>
              <Component {...pageProps} />
            </ApolloProvider>
          </ErrorStateProvider>
        </LoadingProvider>
      </Provider>
    </Web3ReactProvider>
  )
}

/**
 *
 * @param {*} appContext
 * @returns
 */
MyApp.getInitialProps = async (appContext) => {


  let serverToken;
  let { ctx } = appContext
  let accessToken = nookies.get(ctx)[`${process.env.INITIAL_TOKEN}access_token`];
  let guestToken = nookies.get(ctx)[`${process.env.INITIAL_TOKEN}guest_token`];
  let client;
  let user_status

  if (accessToken) {
    client = initializeApollo(accessToken)
    user_status = true;
    serverToken = accessToken
  } else if (guestToken) {
    client = initializeApollo(guestToken);
    user_status = false
    serverToken = guestToken
  } else {
    let { token, user_status } = await TokenValidation(ctx);
    client = initializeApollo(token);
    user_status = false
    serverToken = token
  }

  // const { token, user_status } = await TokenValidation(appContext.ctx) //Validate to use guest token or access token for authenticted user

  //Define dispatch
  const reduxStore = initializeStore()
  const { dispatch } = reduxStore
  //Define Apollo client with token
  //Get only in ssr mode
  if (typeof window === "undefined") {
    //!Get Initial data function below here, recomended to separate the function...
    if (user_status) {
      let { user_data, initial_data, countries, catalogs, catalogFeatured } =
        await getInitialData(user_status, client, appContext.ctx)
      dispatch(setUserData(user_data))
      dispatch({ type: SET_COUNTRIES, payload: countries }) //!Set data to redux
      dispatch({ type: SET_CATALOGS, payload: catalogs })
      dispatch({ type: SET_CATALOGS_FEATURED, payload: catalogFeatured })
    } else {
      let { cartGuest, catalogFeatured } = await getGuestData(
        client,
        appContext.ctx
      )
      // if (data) {
      dispatch(setCarts(cartGuest))
      dispatch({ type: SET_CATALOGS_FEATURED, payload: catalogFeatured })
      // }
    }
  }
  const cookies = nookies.get(appContext?.ctx)
  const currency = cookies?.currency
  // if (!currency) {
  //   nookies.set(appContext?.ctx, "currency", "IDR", {
  //     maxAge: 30 * 24 * 60 * 60,
  //     path: "/",
  //   })
  // } else {
  //   dispatch({
  //     type: SET_CURRENCY,
  //     payload: { label: currency, id: currency === "IDR" ? 0 : 1 },
  //   })
  // }

  const appProps = await App.getInitialProps(appContext)

  appProps.pageProps.initialReduxState = reduxStore.getState()

  return {
    ...appProps,
    token: serverToken,
  }
}

// MyApp.getInitialProps = async appContext => getInitialData(appContext, async (appContext, reduxStore, token) => {
//   const appProps = await App.getInitialProps(appContext);
//   appProps?.pageProps?.initialReduxState = reduxStore.getState();
//   return { ...appProps, token }
// })

export default MyApp

// import React from 'react'
// import "assets/scss/main.scss"
// import "react-datepicker/dist/react-datepicker.css";
// import { LoadingProvider } from "context/loading-context"
// import { useStore } from "lib/redux";
// import App from "next/app";
// import { Router } from "next/router";
// import nProgress from "nprogress";
// import { Provider } from "react-redux";
// import { useApollo } from "lib/apollo";
// import { ApolloProvider } from '@apollo/client';
// import TagManager from "react-gtm-module";
// import { getInitialData } from 'lib/function/get-initialdata'

// //Maintenance Components
// import MaintenanceComp from 'components/pages/maintenance'

// //NProgress Loading
// nProgress.configure({ showSpinner: false })
// Router.events.on('routeChangeStart', url => nProgress.start())
// Router.events.on('routeChangeComplete', () => nProgress.done())
// Router.events.on('routeChangeError', () => nProgress.done())

// function MyApp({ Component, pageProps, token, status }) {

//   const client = useApollo(pageProps?.initialApolloState, token)

//   const store = useStore(pageProps?.initialReduxState)

//   return (
//     <LoadingProvider>
//       <Provider store={store}>
//         <ApolloProvider client={client}>
//           <Component {...pageProps} />
//         </ApolloProvider>
//       </Provider>
//     </LoadingProvider>
//   )
// }

// MyApp.getInitialProps = async appContext => getInitialData(appContext, async (appContext, reduxStore, token) => {
//   const appProps = await App.getInitialProps(appContext);
//   appProps.pageProps?.initialReduxState = reduxStore.getState();
//   return { ...appProps, token }
// })

// export default MyApp
